@import "../../styles/Variables.css";

.section {
  display: flex;
  align-items: baseline;
  font-size: 1.5rem;
  margin: 0.75rem 0 0;
}

.section:after {
  content: "";
  height: 1px;
  background-color: #000;
  flex: 1;
  margin-left: 5px;
}

.highlight {
  color: var(--blue);
}
