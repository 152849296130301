@import url("../../styles/Variables.css");

.links-section {
  margin-top: var(--margin-top-x-large);
  font-size: 0.9rem;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.links-section a {
  color: var(--dark-gray);
  text-decoration: underline;
}

.links-section a .icon {
  margin-right: 0.25rem;
}

.dot {
  font-size: 0.75rem;
  color: var(--dark-gray);
  user-select: none;
  margin: 0 1rem;
}

@media (max-width: 480px) {
  .links-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .links-section .dot {
    display: none;
  }
  
  .links-section a {
    margin: 0.2rem 0;
    width: 100%;
  }
}
