@import url("../../styles/Variables.css");

.skill-table {
  margin-top: var(--margin-top-regular);
  width: 100%;
  border-collapse: collapse;
}

tr td {
  padding: 0.2rem 0;
}

.label-cell {
  text-align: right;
  vertical-align: top;
  padding-right: 10px;
  width: 100px;
}

.text-cell {
  vertical-align: top;
}

.text-content {
  font-weight: var(--font-weight-light);
}
