:root {
  /* Colors */
  --blue: #4169e1;
  --dark-gray: #222;
  /* Fonts */
  --font-family-primary: "Fira Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  /* Font Weights */
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  /* Margins */
  --margin-top-small: 0.2rem;
  --margin-top-regular: 0.3rem;
  --margin-top-large: 0.4rem;
  --margin-top-x-large: 0.5rem;
}

/* Font Style Classes */

/* Normal */
.fira-sans-thin {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-thin);
  font-style: normal;
}

.fira-sans-light {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-light);
  font-style: normal;
}

.fira-sans-regular {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}

.fira-sans-medium {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}

.fira-sans-bold {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-style: normal;
}

.fira-sans-black {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-black);
  font-style: normal;
}

/* Italic */
.fira-sans-thin-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-thin);
  font-style: italic;
}

.fira-sans-light-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-light);
  font-style: italic;
}

.fira-sans-regular-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  font-style: italic;
}

.fira-sans-medium-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-medium);
  font-style: italic;
}

.fira-sans-bold-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-style: italic;
}

.fira-sans-black-italic {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-black);
  font-style: italic;
}

/* Top margins */
.margin-top-regular {
  margin-top: var(--margin-top-regular);
}

.flex-row-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title-and-date {
  margin-top: var(--margin-top-large);
}
