@import url("./styles/Variables.css");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;

  /* Fonts */

  /* Default to .fira-sans-black from Variables.css */
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-black);
  font-style: normal;
  /* Default color */
  color: var(--dark-gray);
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  align-items: start;
  height: 100vh;
  justify-items: center;
}

header {
  padding: 2rem 0 0 0;
  margin-top: var(--margin-top-x-large);
  text-align: center;
}

h1 {
  font-size: 2.75rem;
  font-weight: var(--font-weight-bold);
}

.main-content {
  width: 95vw;
  max-width: 900px;
  padding-bottom: 4rem;
}

.highlight {
  padding: 0.5rem 0;
}

.highlight strong {
  font-weight: var(--font-weight-bold);
  color: var(--blue);
}
