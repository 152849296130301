@import url("../../../styles/Variables.css");

.accomplishment {
  margin-left: 1.5rem;
  margin-top: var(--margin-top-x-large);
}

ul li {
  padding-bottom: 0.25rem;
}

ul li::marker {
  font-size: 0.7em;
}

ul ul {
  margin-left: 1.5em;
}

ul ul li {
  padding-bottom: 0.2rem;
}
