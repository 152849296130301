@import url("../../../styles/Variables.css");

.subtitle-and-location {
  margin-top: var(--margin-top-small);
}

.location {
  font-size: 0.9rem;
  color: var(--blue);
}
